<template>
    <div>
        <v-container
            fluid
        >
            <!-- Setup Card -->
            <v-card class="mb-4" outlined :loading="loading">
                <v-card-title class="text-h5 mt-3">Quotes Ranges</v-card-title>
                <v-card-text class="pa-4">
                    <p class="mt-n7">Configure your quote by age ranges.</p>
                    
                    <!-- Inputs for range setup -->
                    <v-row class="mt-2 mb-2">
                        <v-col cols="4">
                            <v-text-field
                                label="Days From"
                                v-model="newRange.from"
                                type="number"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                label="Days To"
                                v-model="newRange.to"
                                type="number"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="d-flex align-center mt-n6">
                            <v-btn icon color="primary" depressed @click="addRange" rounded>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div 
                        class="mt-n3" 
                        style="max-height: 400px; overflow-y: auto;"
                    >
                        <v-data-table
                            :headers="setupHeaders"
                            :items="quotesByAgesRanges"
                            dense
                            hide-default-footer
                            disable-pagination
                            :mobile-breakpoint="0"
                            :sort-by="'to'"
                            :sort-asc="true"
                        >
                            <template v-slot:top>
                                <v-toolbar flat style="background-color: #eeeeee;">
                                    <v-toolbar-title>Added Ranges</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                            </template>

                            <template v-slot:item.actions="{ item, index }">
                                <div class="d-flex">
                                    <v-btn icon small @click="removeDialog(index)">
                                        <v-icon color="red" style="font-size: 20px;">mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
        <Errors/>
        <!-- Range Dialogs -->
        <v-dialog max-width="450px" v-model="dialog" persistent>
            <v-card>
                <!-- Remove range -->
                <v-card-title v-if="removeRangeDialog" class="text-h5">Remove Range</v-card-title>
                <v-card-text v-if="removeRangeDialog">
                    Are you sure you want to remove this range?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" 
                        @click="closeDialog"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="removeRangeDialog"
                        @click="removeRange"
                        text
                        color="error"
                    >
                        Remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
export default {
    name: 'QuoteRangesSettings',
    props: {
        setting: Object,
        commercialTerms: Array,
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
    },
    data() {
        return {
            newRange: {
                from: null,
                to: null,
            },
            quotesByAgesRanges: [],
            setupHeaders: [
                { text: "Days From", value: "from", align: "start", sortable: true },
                { text: "Days To", value: "to", sortable: true},
                { text: "Actions", value: "actions", sortable: false },
            ],
            dialog: false,
            removeRangeDialog: false,
            loading: false,
        }
    },
    async mounted() {
        this.quotesByAgesRanges = await this.fetchQuoteRanges()
        if (this.quotesByAgesRanges) {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async fetchQuoteRanges() {
            try {
                this.loading = true
                const response = await API.getQuoteRanges(this.setting.id)
                const quoteRangesArray = Array.isArray(response.quoteRanges[0]) 
                    ? response.quoteRanges[0] 
                    : response.quoteRanges
                return quoteRangesArray.quoteRanges
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeDialog() {
            this.selectedRange = null
            this.dialog = false
            this.removeRangeDialog = false
        },
        removeDialog(index) {
            this.selectedRange = index
            this.dialog = true
            this.removeRangeDialog = true
        },
        isNumber(newRange) {
            const from = parseInt(newRange.from)
            const to = parseInt(newRange.to)

            if (isNaN(from) || isNaN(to)) {
                return false
            }
            return true
        },
        isRangeValid(newRange) {
            if (newRange.from === null || newRange.to === null || parseInt(newRange.from) >= parseInt(newRange.to)) {
                return false
            }
            for (let range of this.quotesByAgesRanges) {
                const condition1 = parseInt(newRange.from) < parseInt(range.to)
                const condition2 = parseInt(newRange.to) > parseInt(range.from)
                
                if (condition1 && condition2) {
                    return false
                }
            }
            return true
        },
        async addRange() {
            if(this.newRange != null) {
                if (this.isRangeValid(this.newRange)) {
                    if (this.isNumber(this.newRange)) {
                        this.quotesByAgesRanges.push({ ...this.newRange})
                        this.quotesByAgesRanges.sort((a, b) => a.to - b.to)
                        try {
                            await API.updateQuoteRanges(this.setting, this.quotesByAgesRanges)
                        } catch(error) {
                            this.setErrorItems({
                            source: this.$optiones.name,
                            message: error.message,
                        })}
                        this.newRange.from = null
                        this.newRange.to = null
                    } else {
                        this.setErrorItems({
                        source: this.$options.name,
                        message: 'The range should be a numeric value.',
                        })
                    }
                } else {
                    this.setErrorItems({
                        source: this.$options.name,
                        message: 'The range intersects with an existing one. Please choose another range.',
                    })
                }
            } else {
                this.setErrorItems({
                source: this.$options.name,
                message: 'The range cannot be empty. Please provide a value.',
                })
            }
        },
        async removeRange() {
            this.quotesByAgesRanges.splice(this.selectedRange, 1)
            this.quotesByAgesRanges.sort((a, b) => a.to - b.to)
            try {
                await API.updateQuoteRanges(this.setting, this.quotesByAgesRanges)
                this.dialog = false
                this.removeRangeDialog = false
                this.selectedRange = null
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}

</script>